<template>
  <div class="table-action-menu">
    <div class="table-action-menu__wrapper">
      <div class="table-action-menu__activator">
        <button
          type="button"
          class="table-action-menu__activator-btn"
          role="button"
          aria-haspopup="true"
          title="Toggle options for this item"
          :disabled="disabled"
          data-testid="table-action-trigger"
          @click.stop="toggleMenu"
        >
          <bg-icon name="more-vertical" />
        </button>
      </div>
      <div role="menu" class="table-action-menu__options">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { BgIcon } from 'bangul-vue';

export default {
  name: 'TableActionMenu',

  components: {
    BgIcon,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
  },

  beforeMount() {
    window.isHandleClickBackdropInstalled = false;
  },

  mounted() {
    if (!window.isHandleClickBackdropInstalled) {
      window.addEventListener('click', this.handleClickBackdrop);
      window.isHandleClickBackdropInstalled = true;
    }
  },

  beforeDestroy() {
    window.removeEventListener('click', this.handleClickBackdrop);
    delete window.isHandleClickBackdropInstalled;
  },

  methods: {
    toggleMenu() {
      const allMenuElm = document.querySelectorAll('.table-action-menu');

      /* eslint-disable no-restricted-syntax */
      for (const menu of allMenuElm) {
        if (menu === this.$el) {
          this.$el.classList.toggle('table-action-menu--opened');
        } else {
          menu.classList.remove('table-action-menu--opened');
        }
      }
      /* eslint-enable no-restricted-syntax */
    },

    handleClickBackdrop() {
      const allMenuElm = document.querySelectorAll('.table-action-menu');

      /* eslint-disable no-restricted-syntax */
      for (const menu of allMenuElm) {
        menu.classList.remove('table-action-menu--opened');
      }
      /* eslint-enable no-restricted-syntax */
    },
  },
};
</script>

<style lang="scss" scoped>
.table-action-menu {
  display: flex;
  justify-content: center;

  &#{&}--opened {
    .table-action-menu__options {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      transition: 0.3s;
    }
  }

  &__wrapper {
    position: relative;
    display: inline-block;
  }

  &__activator {
    &-btn {
      position: relative;
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &__options {
    position: absolute;
    right: 0;
    transform: translateY(-10%) scale(0.95);
    min-width: 220px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
    z-index: 2;
    text-align: left;
    visibility: hidden;
    opacity: 0;

    .bg-c-list-item {
      padding: 10px;

    }
  }

  ::v-deep .bg-c-list-item__description {
    color: #bbb;
    user-select: none;
  }

  .bg-c-list-item--clickable {
    ::v-deep .bg-c-list-item__description {
      color: #404040;;
    }
    &:hover {
      background-color: #2f1e5515;
      border-radius: 4px;
    }
  }

  .table-action-menu__activator-btn {
    &:disabled {
      opacity: .5;
      cursor: default;
    }
  }
}
</style>
