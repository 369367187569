<template>
  <div class="additional-cost-detail">
    <div class="flex align-center mb-24">
      <router-link :to="detailTransferLink" class="back-button">
        <bg-icon name="back" size="lg" />
      </router-link>
      <bg-text size="heading-2" class="ml-16"> Biaya Tambahan Penyewa </bg-text>
    </div>

    <div v-if="isLoadingGetData" class="mb-24">
      <div class="mb-16"><bg-skeleton width="25%" :height="30" /></div>
      <div><bg-skeleton width="20%" :height="25" /></div>
    </div>
    <template v-else-if="isSuccessGetData">
      <div class="flex align-center justify-space-between mb-16">
        <bg-text size="heading-3">{{ invoice.property_name }}</bg-text>
        <bg-button variant="secondary" size="lg" @click="handleOpenAddCost()"
          >Tambah Biaya</bg-button
        >
      </div>
      <div class="mb-24">
        <bg-text size="body-landing">
          {{ invoice.tenant_name }} -
          <span class="td-u">{{ invoice.invoice_number }}</span>
        </bg-text>
      </div>
    </template>

    <table class="ss-table">
      <thead>
        <tr>
          <th
            v-for="(header, i) in tableHeaders"
            role="columnheader"
            :aria-label="header"
            :key="`header-${i}`"
            :class="header.includes('Action') ? 'ta-c' : 'ta-l'"
          >
            <bg-text size="title-5">{{ header }}</bg-text>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoadingGetData">
          <tr v-for="i in 3" :key="`loading-tr-${i}`">
            <td v-for="(header, j) in tableHeaders" :key="header + i + j">
              <bg-skeleton :height="20" width="100%" />
            </td>
          </tr>
        </template>

        <template v-if="isSuccessGetData">
          <tr v-for="cost in costs" :key="`cost-${cost.id}`">
            <td>
              {{ cost.name }}
            </td>
            <td>
              {{ cost.amount | rupiah }}
            </td>
            <td>
              {{ cost.disburse_amount | rupiah }}
            </td>
            <td>
              {{ cost.source | capitalize }}
            </td>
            <td>
              <table-action-menu>
                <bg-list-item clickable @click="handleOpenAddCost(cost)"
                  >ubah</bg-list-item
                >
                <bg-list-item
                  v-if="cost.showDeleteButton"
                  clickable
                  :data-testid="`delete-cost-${cost.id}`"
                  @click="handleOpenDeleteCostModal(cost.id)"
                  >Hapus</bg-list-item
                >
              </table-action-menu>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <cost-modal
      :is-open.sync="isCostModalOpen"
      :cost-data.sync="selectedCost"
      @action-success="fetchCostData"
    />

    <delete-cost-modal
      :is-open.sync="isDeleteCostModalOpen"
      :cost-id.sync="selectedCostId"
      @action-success-delete="fetchCostData"
    />
  </div>
</template>

<script>
import { BgIcon, BgText, BgSkeleton, BgListItem, BgButton } from 'bangul-vue';
import TableActionMenu from '@admin_molecules/TableActionMenu';
import detailTransferApi from '@admin_endpoints/detail-transfer';
import { rupiahFormatter } from 'Utils/formatter';
import { capitalize } from 'Utils/typography';

const AsyncState = {
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export default {
  name: 'AdditionalCostDetail',

  components: {
    BgIcon,
    BgText,
    BgSkeleton,
    BgListItem,
    BgButton,
    TableActionMenu,
    CostModal: () => import('./partials/CostModal'),
    DeleteCostModal: () => import('./partials/DeleteCostModal'),
  },

  filters: {
    capitalize,
    rupiah: value => {
      /* eslint-disable-next-line no-restricted-globals */
      if (!value || value === '-' || !isFinite(value)) {
        return '-';
      }

      return rupiahFormatter(String(value).replace(/\..+/, ''));
    },
  },

  data() {
    return {
      currentAsyncState: AsyncState.INITIAL,
      costs: [],
      invoice: {},
      tableHeaders: [
        'Nama Biaya',
        'Jumlah Biaya',
        'Transfer ke Pemilik',
        'Asal Biaya',
        'Action',
      ],
      isCostModalOpen: false,
      selectedCost: {},
      isDeleteCostModalOpen: false,
      selectedCostId: null,
    };
  },

  computed: {
    approvalId() {
      return this.$route.params.approval_id;
    },
    invoiceId() {
      return this.$route.params.disbursement_invoice_id;
    },
    isLoadingGetData() {
      return this.currentAsyncState === AsyncState.LOADING;
    },
    isSuccessGetData() {
      return this.currentAsyncState === AsyncState.SUCCESS;
    },
    detailTransferLink() {
      return {
        name: 'disbursement.detail-transfer',
        params: {
          approval_id: this.approvalId,
        },
      };
    },
  },

  created() {
    this.fetchCostData();
  },

  methods: {
    async fetchCostData() {
      this.currentAsyncState = AsyncState.LOADING;

      try {
        const { data } = await detailTransferApi.getAdditionalCost(
          this.approvalId,
          this.invoiceId
        );

        this.invoice = data.data.info || {};
        this.costs = (data.data.cost || []).map(cost => ({
          ...cost,
          showDeleteButton: cost.source === 'manual',
        }));

        this.currentAsyncState = AsyncState.SUCCESS;
      } catch (error) {
        console.error(error);
        this.currentAsyncState = AsyncState.ERROR;
      }
    },

    handleOpenAddCost(cost = {}) {
      this.selectedCost = { ...cost };
      this.isCostModalOpen = true;
    },

    handleOpenDeleteCostModal(costId) {
      this.selectedCostId = costId;
      this.isDeleteCostModalOpen = true;
    },
  },
};
</script>

<style lang="scss" src="./AdditionalCostDetail.scss" scoped></style>
